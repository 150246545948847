import React from "react";
import async from "../components/Async";
import { User } from "react-feather";

// Pages components
const Profile = async(() => import("../pages/Profile"));

const profileRoutes = {
  id: "Profile",
  path: "/",
  icon: <User />,
  component: Profile,
  children: null
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  profileRoutes
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  profileRoutes
];
