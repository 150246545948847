import React from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import Logo from '../asset/img/logo.png';
import {
  Grid,
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

function setDirection(dir) {
  document.body.style.direction = dir;
}

const languages = [
  { code: 'US', label: 'United States', lang: 'English', phone: '1' },
  { code: 'IL', label: 'Israel', lang: 'Hebrew', phone: '972' },
  { code: 'ES', label: 'Spain', lang: 'Spanish', phone: '34' },
  { code: 'RU', label: 'Russian', lang: 'Russian', phone: '7' }
]

function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [lang, setLang] = React.useState({
    "lang" : "English",
    "code" : "US"
  });
  const { i18n } = useTranslation('common');
  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };
  const closeMenu = (lang, code) => {
    setAnchorMenu(null);
    setLang({
      "lang" : lang,
      "code" : code
    })
    if (code === "IL") {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
    i18n.changeLanguage(code.toLowerCase());
  };
  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        className="lang-label"
      >
        {countryToFlag(lang.code)}
      </IconButton>
      <div className="header-lang-text" onClick={toggleMenu}>{lang.lang}</div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={(e) => setAnchorMenu(null)}
      >
        {languages && languages.map(item => {
          return <MenuItem onClick={(e) => closeMenu(item.lang, item.code)}>
            {item.lang}
          </MenuItem>
        })}
      </Menu>
    </React.Fragment>
  )
}

const Header = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item>
            <div className="row header-cont">
              <div className="col-md-12 header-logo">
                <img src={Logo} alt="logo" width="70px" height="70px" />
                <div>DISCOVERING LIFE IN TEN <br />
                    <span>World Kabbalah Convention 2021</span>
                  </div>
              </div>
            </div>
          </Grid>
          <Grid item xs />
          <Grid item className="lang-menu">
            <LanguageMenu/>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default connect()(withTheme(Header));
