import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { spacing } from "@material-ui/system";
import {
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";
import Header from "../components/Header";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;


const Dashboard = ({children, width}) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <Header/>
        <MainContent p={isWidthUp("lg", width) ? 10 : 5}>
          {children}
        </MainContent>
      </AppContent>
    </Root>
  )
}

export default withWidth()(Dashboard);
